<template>
  <span>{{text(props.enumKey, props.byValue)}}</span>
</template>

<script setup>
import {defineProps} from "vue";
import {getEnumTextByValue} from "@/store/EnumUtils";

const props = defineProps({
  enumKey: String,
  byValue: String
})
const text = getEnumTextByValue
</script>

<style scoped>

</style>
